import * as React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import "./rooms.css"
import { useTranslation } from "gatsby-plugin-react-i18next"

const sliderConfig = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: "slider",
}

const Rooms: React.FC<{ isReady: boolean }> = ({ isReady }) => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      priceImage: file(relativePath: { eq: "DSC05451.jpg" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      otputtonyos: allFile(
        filter: { absolutePath: { regex: "/images/otputtonyos/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 800, quality: 80) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }
      esszencia: allFile(
        filter: { absolutePath: { regex: "/images/esszencia/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 800, quality: 80) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }

      furmint: allFile(
        filter: { absolutePath: { regex: "/images/furmint/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 800, quality: 80) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }

      borhaz: allFile(
        filter: { absolutePath: { regex: "/images/borhaz/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 800, quality: 80) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }
    }
  `)
  const [showLightBox, setShowLightBox] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState<null | number>(null)
  const [images, setImages] = React.useState([])

  const [LazyComponents, setLazyComponents] = React.useState<{
    Slider: React.FC<any> | null
    LightBox: React.FC<any> | null
  }>({
    Slider: null,
    LightBox: null,
  })

  if (isReady && !LazyComponents.Slider) {
    Promise.all([import("react-slick"), import("./lightbox")]).then(
      ([slider, lightbox]) => {
        setLazyComponents({
          // @ts-ignore
          Slider: slider.default,
          LightBox: lightbox.default,
        })
      }
    )
  }

  type GroupT = "otputtonyos" | "esszencia" | "furmint" | "borhaz"
  const getImages = (group: GroupT) =>
    data[group].edges.map((edge: any) => edge.node.childImageSharp.fluid)

  const handleClose = () => {
    setShowLightBox(false)
    setSelectedImage(null)
    if (window.location.href.includes("gallery")) {
      window.history.back()
    }
  }
  const handlePrevRequest = (i: number, length: number) => () => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i: number, length: number) => () => {
    setSelectedImage((i + 1) % length)
  }

  React.useEffect(() => {
    if (!window.location.href.includes("gallery")) {
      handleClose()
    }
  }, [typeof window !== `undefined` && window.location.href])

  const handleOpen = (group: GroupT, index: number) => () => {
    if (window.innerWidth < 1024) {
      return
    }

    window.history.pushState("", "", `${window.location.hash}?gallery`)

    setImages(getImages(group))
    setSelectedImage(index)
    setShowLightBox(true)
  }

  return (
    <div className="rooms container">
      {showLightBox && LazyComponents.LightBox && (
        <LazyComponents.LightBox
          images={images}
          handleClose={handleClose}
          handleNextRequest={handleNextRequest}
          handlePrevRequest={handlePrevRequest}
          selectedImage={selectedImage}
        />
      )}

      <div className="box">
        <h3>{t("ROOMS.ROOM_1.TITLE")}</h3>

        <div aria-hidden>
          {!LazyComponents.Slider ? (
            <div className="slider-placeholder">{t("GLOBAL.LOADING")}</div>
          ) : (
            <LazyComponents.Slider {...sliderConfig}>
              {getImages("otputtonyos").map((fluid: any, i: number) => (
                <button
                  className="enlarge"
                  onClick={handleOpen("otputtonyos", i)}
                  key={i}
                >
                  <Img fluid={fluid} />
                </button>
              ))}
            </LazyComponents.Slider>
          )}
        </div>

        <ul>
          <li>{t("ROOMS.ROOM_1.PROP_1")}</li>
          <li>{t("ROOMS.ROOM_1.PROP_2")}</li>
          <li>{t("ROOMS.ROOM_1.PROP_3")}</li>
        </ul>
        <div>{t("ROOMS.ROOM_1.DESCRIPTION")}</div>
      </div>

      <div className="box">
        <h3>{t("ROOMS.ROOM_2.TITLE")}</h3>

        <div aria-hidden>
          {" "}
          {!LazyComponents.Slider ? (
            <div className="slider-placeholder">{t("GLOBAL.LOADING")}</div>
          ) : (
            <LazyComponents.Slider {...sliderConfig}>
              {getImages("esszencia").map((fluid: any, i: number) => (
                <button
                  className="enlarge"
                  onClick={handleOpen("esszencia", i)}
                  key={i}
                >
                  <Img fluid={fluid} />
                </button>
              ))}
            </LazyComponents.Slider>
          )}
        </div>

        <ul>
          <li>{t("ROOMS.ROOM_2.PROP_1")}</li>
        </ul>
      </div>

      <div className="box">
        <h3>{t("ROOMS.ROOM_3.TITLE")}</h3>

        <div aria-hidden>
          {!LazyComponents.Slider ? (
            <div className="slider-placeholder">{t("GLOBAL.LOADING")}</div>
          ) : (
            <LazyComponents.Slider {...sliderConfig}>
              {getImages("furmint").map((fluid: any, i: number) => (
                <button
                  className="enlarge"
                  onClick={handleOpen("furmint", i)}
                  key={i}
                >
                  <Img fluid={fluid} />
                </button>
              ))}
            </LazyComponents.Slider>
          )}
        </div>

        <ul>
          <li>{t("ROOMS.ROOM_3.PROP_1")}</li>
        </ul>
      </div>

      <div className="box">
        <h3>{t("ROOMS.ROOM_4.TITLE")}</h3>
        <div aria-hidden>
          {" "}
          {!LazyComponents.Slider ? (
            <div className="slider-placeholder">{t("GLOBAL.LOADING")}</div>
          ) : (
            <LazyComponents.Slider {...sliderConfig}>
              {getImages("borhaz").map((fluid: any, i: number) => (
                <button
                  className="enlarge"
                  onClick={handleOpen("borhaz", i)}
                  key={i}
                >
                  <Img fluid={fluid} />
                </button>
              ))}
            </LazyComponents.Slider>
          )}
        </div>

        <ul>
          <li>{t("ROOMS.ROOM_4.PROP_1")}</li>
        </ul>
      </div>

      <div className="box full">
        <div className="list">
          <div className="left">
            <div>{t("ROOMS.PRICES.ROW_1_TITLE")}</div>
            <div>{t("ROOMS.PRICES.ROW_2_TITLE")}</div>
            <div>{t("ROOMS.PRICES.ROW_3_TITLE")}</div>
          </div>
          <div className="right">
            <div>{t("ROOMS.PRICES.ROW_1_VALUE")}</div>
            <div>{t("ROOMS.PRICES.ROW_2_VALUE")}</div>
            <div>{t("ROOMS.PRICES.ROW_3_VALUE")}</div>
          </div>
        </div>
        <ul className="misc">
          <li>{t("ROOMS.MISC.ITEM_1")}</li>
          <li>
            <a
              href="https://szepkartya.hu/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {t("ROOMS.MISC.ITEM_2")}
            </a>
          </li>
          <li>{t("ROOMS.MISC.ITEM_3")}</li>
          <li>{t("ROOMS.MISC.ITEM_4")}</li>
        </ul>
        <div
          className="price-image"
          style={{
            height: "300px",
            backgroundImage: `linear-gradient(
              to top,
          rgba(255, 255, 255, 0.0),
          rgba(255, 255, 255, 1)
          ),
          url(${data.priceImage.childImageSharp.fixed.src})`,
            boxShadow: `rgba(87, 87, 86, 0.2) 0px 4px 3px -2px`,
          }}
        />
      </div>
    </div>
  )
}

export default Rooms
