import * as React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Extra = () => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "DSC05573.jpg" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <p>{t("EXTRA.TEXT_1")}</p>
      <Img
        fixed={data.contact.childImageSharp.fixed}
        className="welcome-illustration"
        aria-labelledby="welcome"
      />
      <p>{t("EXTRA.TEXT_2")}</p>
      <p>{t("EXTRA.TEXT_3")}</p>
      <p>{t("EXTRA.TEXT_4")}</p>
    </>
  )
}

export default Extra
