import * as React from "react"
import { PageProps } from "gatsby"
import App from "../components/app"

interface DataProps {}

const IndexPage: React.FC<PageProps<DataProps>> = () => {
  return <App />
}

export default IndexPage
