import * as React from "react"
import "./facilities.css"

import svg9 from "../images/illustration/9.svg"
import svg4 from "../images/illustration/4.svg"
import svg3 from "../images/illustration/3.svg"
import svg8 from "../images/illustration/8.svg"
import svg6 from "../images/illustration/6.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Facilities = () => {
  const { t } = useTranslation()

  return (
    <div className="facilities container">
      {/*<div className="box">*/}
      {/*  <img src={svg7} aria-labelledby="reggeli" />*/}
      {/*  <h3 id="reggeli">Reggeli</h3>*/}
      {/*</div>*/}
      <div className="box">
        <img src={svg6} aria-labelledby="zart-p" aria-hidden />
        <h3 id="zart-p">{t("FACILITIES.ITEM_1")}</h3>
      </div>
      <div className="box">
        <img src={svg4} aria-labelledby="wifi" aria-hidden />
        <h3 id="wifi">{t("FACILITIES.ITEM_2")}</h3>
      </div>
      <div className="box">
        <img src={svg8} aria-labelledby="pincel" aria-hidden />
        <h3 id="pincel">{t("FACILITIES.ITEM_3")}</h3>
      </div>
      <div className="box">
        <img src={svg3} aria-labelledby="bork" aria-hidden />
        <h3 id="bork">{t("FACILITIES.ITEM_4")}</h3>
      </div>
      <div className="box">
        <img src={svg9} aria-labelledby="kerekpar" aria-hidden />
        <h3 id="kerekpar">{t("FACILITIES.ITEM_5")}</h3>
      </div>
    </div>
  )
}

export default Facilities
